





















import { Component, Watch, Vue, Prop } from 'vue-property-decorator';

import * as d3 from 'd3';

import data_gemeente_utrecht from '@/../data/datasets_gemeente_utrecht.json';
import meta_gemeente_utrecht from '@/../data/meta_gemeente_utrecht.json';
import similarities_utrecht from '@/../data/similarities_gemeente_utrecht.json';

@Component({
  components: { },
})
export default class Home extends Vue {

    @Prop(String)
    map_definition: string;

    datasets = [];
    meta_definition: any = null;
    similarities: number[][] = [];

    selected: any = null;
    mouse_pos: any = null;

    @Prop({ default: 0 }) readonly zoom_level: number;
    @Prop({ type: String, required: false, default: null })
    selection_key: any;

    mounted(): void {
        this.loadDatasets();
        this.renderSvg();
    }

    renderSvg(): void {
        const svg = d3.select('#dataset-relation-map');
        const container = d3.select('#dataset-relation-map-container');
        container.call(d3.zoom().on("zoom", function({transform}) {
            svg.attr("transform", transform);
        }));
        let nodes = this.datasets
            // .splice(0, 500)
            .map((n) => {
                n.x = -300 + Math.random() * 600;
                n.y = -300 + Math.random() * 600;
                return n;
            })
        ;
        let links = [];
        for (let i in this.similarities)  {
            for (let j in this.similarities[i]) {
                if (i == j) continue;
                if (parseInt(i) >= nodes.length || parseInt(j) >= nodes.length) continue;
                let similarity = this.similarities[i][j];
                if (similarity < 0.1) continue;

                links.push({
                    source: parseInt(i),
                    target: parseInt(j),
                    similarity
                });
            }
        }

        const simulation = d3.forceSimulation(nodes)
            .force('charge', d3.forceManyBody())
            .force('collision', d3.forceCollide().radius(5))
            .force('similarities', d3.forceLink(links)
                .distance(l => {
                    return (1 / l.similarity);
                })
                .strength(() => 0.04)
            )
            .force('x', d3.forceX().strength(0.08))
            .force('y', d3.forceY().strength(0.08))
        ;

        let node = svg.append("g")
            .selectAll('g')
            .data(nodes)
            .join('g')
            .attr('class', 'dataset-node')
        ;

        node.append('text')
            .text(d => d.title)
            .attr('fill', 'white')
            .attr('text-anchor', 'middle')
            .attr('y', '20px')
        ;
        node.append('circle')
            .attr('stroke', 'white')
            .attr('stroke-width', 1)
            .attr('fill', '#cccc44')
            .attr('r', 5)
            .on('mouseover', (e) => {
                console.log(e);
                this.mouse_pos = {
                    x: e.x,
                    y: e.y,
                }
                this.selected = e.target.__data__;
            })
            .on('mouseleave', (e) => {
                if (this.selected == e.target.__data__) {
                    this.selected = null;
                }
            });

        simulation.on('tick', () => {
            node.attr('transform', d => `translate(${d.x}, ${d.y})`);
        });
    }

    @Watch('map_definition')
    loadDatasets(): void {
        this.datasets = similarities_utrecht.documents.map((x) => ({"title": x}));
        this.meta_definition = meta_gemeente_utrecht;
        this.similarities = similarities_utrecht.similarities;
    }
}

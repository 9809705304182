<template>
    <div class="header">
        <img src="/datogether_logo_t2.png"
             @click="$router.push('/')"
             class="logo"
        >
        <h1>{{ meta.map_title }} </h1>

        <div class="right-container">
            <input
                 v-model="search_key"
                 placeholder="Zoeken..."
                 class="header-search" />

            <div class="filter-btn"
                 @click="show_filters = !show_filters"
                >
                <i class="fas fa-filter"></i>
            </div>
        </div>

        <select v-model="zoom_level">
            <option
                v-for="(z, index) in meta.zoom_levels"
                :value="index"
                :key="index"
                >
                {{ index }}: {{ z.name }}
            </option>
        </select>


        <div class="filter-overlay"
             v-if="meta != null"
             v-show="show_filters"
            >
            <h3><i class="fas fa-filter"></i> Filters</h3>

            <div class="filter-overlay-inner">

                <div class="filter-field"
                     v-for="field in meta.fields"
                     :key="field.key"
                    >
                    <label>{{ field.name }} </label>
                    <multiselect
                        v-model="filter[field.key]"
                        :options="Object.values(field.values)"
                        label="name"
                        track-by="key"
                        />
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import multiselect from 'vue-multiselect';

export default {

    components: {
        multiselect,
    },

    props: {
        meta: {
            type: Object,
            required: true,
        },
        zoom_level: Number,
    },

    data: () => ({
        search_key: "",
        show_filters: false,
        filter: {},
    }),

    watch: {
        search_key(value) {
            this.$emit('searchUpdate', value);
        },
        filter: {
            deep: true,
            handler: function (value)  {
                this.$emit('filterUpdate', value);
            }
        },
        zoom_level(value) {
            this.$emit('setZoom', value);
        }
    },

    mounted() {
        for (let key of Object.keys(this.meta.fields)) {
            this.$set(this.filter, key, null);
        }
    },
}

</script>

<style lang="scss">

.header {
    background-color: darken($d2g-blue, 10%);
    position: relative;
    color: white;
    padding: 15px;
    padding-left: 50px;

    .logo {
        display: inline-block;
        height: 60px;
        vertical-align: bottom;
        padding: 5px;
        box-sizing: border-box;
        margin-right: 20px;
        border-radius: 5px;
        background-color: transparent;
        transition: background-color .1s ease-out;

        cursor: pointer;

        &:hover {
            background-color: rgba(#ffffff, .1);
        }
    }

    h1 {
        margin: 0;
        margin-bottom: -4px;
        display: inline-block;
        vertical-align: bottom;
    }

    .right-container {
        float: right;
        margin-top: 20px;
        text-align: right;
        margin-right: 25px;
    }

    .header-search {
        padding: 5px;

        width: 200px;
        opacity: .6;
        transition: all .2s ease-out;

        &:focus {
            opacity: 1;
            width: 600px;
            padding: 10px;
            font-size: 20px;
        }
    }

    .filter-btn {
        display: inline-block;
        text-align: center;
        padding: 8px;
        margin-left: 10px;
        border-radius: 5px;
        cursor: pointer;

        transition: color .1s ease-out, background-color .1s  ease-out;

        &:hover {
            color: $d2g-blue;
            background-color: white;
        }
    }

    .filter-overlay {
        width: 900px;
        min-height: 200px;
        position: absolute;
        right: 0;
        top: 100%;
        background-color: darken($d2g-blue, 10%);
        border-radius: 0 0 10px 10px;
        box-sizing: border-box;
        padding: 30px;
        z-index: 1000;
    }

    .filter-overlay-inner {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;
    }

    .filter-field {
        margin-bottom: 30px;
        label {
            display: block;
            font-weight: bold;
            font-size: 18px;
            opacity: .8;
            margin-bottom: 5px;
            text-transform: capitalize;
        }

        select {
            width: 100%;
            padding: 5px;
        }
    }
}
</style>

<template>
    <div class="datamap-outer">
        <div v-if="selection_key != null"
             class="datamap-domain-selection-header"
             :style="{'background-color': selected_domain.color }"
            >
            <i class="fas fa-chevron-right"></i>
                {{ selected_domain.name }}
        </div>

        <div class="datamap-container"
            >
            <div class="datamap-domain"
                 :class="[`domain-index-${index}`,
                         getClassGroupWidth(dataset_group.datasets.length)
                 ]"
                 v-for="(dataset_group, index) in grouped_datasets"
                 :style="{
                      '--base-color': meta.fields[options.category_field].values[dataset_group.domain].color,
                      '--base-color-dark': lighten(meta.fields[options.category_field].values[dataset_group.domain].color, -15),
                      '--base-color-light': lighten(meta.fields[options.category_field].values[dataset_group.domain].color, 10),
                      '--text-color': textcolor(meta.fields[options.category_field].values[dataset_group.domain].color)
                 }"
                 :key="index"
                >

                <h3>
                    <i class="fas" :class="`fa-${meta.fields[options.category_field].values[dataset_group.domain].icon}`"></i>

                    {{ meta.fields[options.category_field].values[dataset_group.domain].name }}
                </h3>
                <div class="inner">
                    <template v-for="(dataset, index) in dataset_group.datasets">
                        <div
                             v-if="index < 24"
                             :key="dataset.title"
                             @click="$emit('select', {
                                      zoom: meta.dataset_level,
                                      key: dataset.id }
                                      )"
                             class="dataset-block"
                             :class="{'hidden': filtered !== null && filtered.indexOf(dataset.title) == -1}"
                            >
                            <span class="title">{{ dataset[options.title_field] }}</span>
                            <br>
                            <span class="subtitle">{{ dataset[options.subtitle_field] }}</span>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Fuse from 'fuse.js';


export default {

    data: () => ({
        grouped_datasets: [],
        idx: null,

        filtered: null,
    }),

    props: {
        search_key: {
            type: String,
            required: false,
            default: ""
        },
        selection_key: {
            type: String,
            required: false,
            default: null,
        },
        filters: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        datasets: {
            type: Array,
            required: true,
        },
        meta: {
            type: Object,
            required: true,
        },
        options: {
            type: Object,
            required: true,
        },
        map_definition: {
            type: String,
            required: false,
            default: null,
        },
    },

    computed: {
        selected_domain() {
            return this.meta.fields[this.options.selection_key]
                .values[this.selection_key];
        }
    },
    watch: {
        search_key(value) {
            if (value == "") {
                this.filtered = null;
                return;
            }

            let results = this.idx.search(value);
            this.filtered = results.map((ref) => ref.item.title);
        },

        datasets() {
            this.loadData()
        },
        filters: {
            deep: true,
            handler: function() {
                this.loadData();
            }
        },
        options() {
            this.loadData();
        },

    },

    mounted() {
        this.loadData();
    },

    methods: {
        textcolor(hex_value) {
            if (hex_value == null) return null;

            let hex = hex_value.slice(1);
            let num = parseInt(hex, 16);

            let r = (num >> 16) / 255;
            let g = ((num >> 8) & 0x00FF) / 255;
            let b = (num & 0x0000FF) / 255;

            let cmin = Math.min(r, g, b),
                cmax = Math.max(r, g, b),
                l = 0;

            l = (cmax + cmin) / 2;
            if (l > .5) return '#0d3c55';
            console.log(l);
            return '#ffffff';
        },

        lighten(hex_value, percentage) {
            if (hex_value == null) return null;

            let hex = hex_value.slice(1);
            let num = parseInt(hex, 16);

            let r = (num >> 16) / 255;
            let g = ((num >> 8) & 0x00FF) / 255;
            let b = (num & 0x0000FF) / 255;

            let cmin = Math.min(r, g, b),
                cmax = Math.max(r, g, b),
                delta = cmax - cmin,
                h = 0,
                s = 0,
                l = 0;

            if (delta == 0) { h = 0; }
            if (cmax == r) { h = ((g - b) / delta) % 6; }
            if (cmax == g) { h = ((b - r) / delta) + 2; }
            if (cmax == b) { h = ((r - g) / delta) + 4; }

            h = Math.round(h * 60);
            if (h < 0) { h += 360; }

            l = (cmax + cmin) / 2;
            l += (percentage / 100);
            s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

            s = (s * 100).toFixed(1);
            l = (l * 100).toFixed(1);

            if (l < 0) { l = 0; }
            if (l > 100) { l = 100; }

            return `hsl(${h}, ${s}%, ${l}%)`;
        },

        getClassGroupWidth(dataset_count) {
            if (dataset_count > 20) return 'col3';
            if (dataset_count > 7) return 'col2';
            return '';
        },

        loadData() {
            let gkey = this.options.category_field;
            let filters = Object.assign({}, this.filters);
            if (this.options.selection_key != null) {
                filters[
                    this.options.selection_key
                ] = { key: this.selection_key };
            }

            let datasets = this.datasets
                .filter((d) => {
                    if (filters == null) return true;

                    return Object.keys(filters)
                        .map((key) => {
                            return filters[key] == null || d[key] == filters[key].key;
                        })
                        .reduce((rv, x) => rv && x, true);
                });

            let groups = datasets.reduce(function(rv, x) {
                let key = x[gkey] || 'Overig';
                (rv[key] = rv[key] || []).push(x);
                return rv;
            }, {});
            this.grouped_datasets = Object.entries(groups)
                .map(([key, value]) => {
                    return { domain: key, datasets: value };
                });
            this.grouped_datasets.sort((a, b) => {
                return b.datasets.length - a.datasets.length;
            });

            this.idx = new Fuse(
                this.datasets,
                {
                    minMatchCharLength: 3,
                    keys: ['description', 'title'],
                }
            );
        },
    },
}
</script>



<style lang="scss">

.datamap-container {
     color: white;
     display: grid;
     grid-template-columns: repeat(5, 1fr);
     grid-column-gap: 20px;
     grid-row-gap: 20px;
     padding: 40px;

     box-sizing: border-box;

    @media only screen and (max-width: 900px) {
        grid-template-columns: 100%;
        padding: 10px;
    }
}


@mixin domain-color($base-color, $text-color) {
    background-color: $base-color;
    color: $text-color;

    h3 { background-color: darken($base-color, 10%); }

    .dataset-block {
        background-color: lighten($base-color, 10%);
        &:hover {
            background-color: lighten($base-color, 15%);
            color: $d2g-blue;
        }
    }
}

.datamap-domain {
    // default colors
    --base-color: #aaaaaa;
    --base-color-dark: #888888;
    --base-color-light: #cccccc;

    background-color: var(--base-color);
    h3 {
        background-color: var(--base-color-dark);
    }
    .dataset-block {
        background-color: var(--base-color-light);
        color: var(--text-color);

        &:hover {
            background-color: var(--base-color-light);
        }
    }


    @media only screen and (max-width: 900px) {
        grid-column: span 1 !important;
        &.col2, &.col3, &.col4, &.col5 {
            .inner {
                display: block !important;
                overflow-wrap: break-word;
            }
        }
    }

    &.col2 {
        grid-column: span 2;

        .inner {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 20px;
        }
    }
    &.col3 {
        grid-column: span 3;

        .inner {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-column-gap: 20px;
        }
    }
    &.col4 {
        grid-column: span 4;

        .inner {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-column-gap: 20px;
        }
    }
    &.col5 {
        grid-column: span 5;

        .inner {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-column-gap: 20px;
        }
    }

    border-radius: 8px;
    padding: 20px;

    h3 {
        font-size: 24px;
        margin: -20px -20px 20px -20px;
        padding: 20px;
        border-radius: 8px 8px 0 0 ;
    }

    .dataset-block {

        margin: 8px -5px;
        padding: 10px;
        position: relative;


        &.hidden {
            display: none;
        }

        transition: all .2s ease-out;
        cursor: pointer;

        &:hover {
            transform: translateX(8px);
        }

        .title {
            font-weight: bold;
            font-size: 18px;
        }
        .subtitle {
            padding-left: 8px;
            opacity: .6;
        }
    }
}


.datamap-domain-selection-header {
    background-color: $dc-0;
    color: $dc-color-0;

    font-weight: bold;
    padding: 15px 40px;
}

</style>

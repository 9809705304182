import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import DataMap from '../views/DataMap.vue'

import Similarities from '../views/Similarity.vue';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/m/:map_definition/:zoom_level/:selection_key',
        name: 'Map-Specific-WithZoom',
        component: DataMap,
        props: (route) => {
            return {
                map_definition: route.params.map_definition,
                selection_key: route.params.selection_key,
                zoom_level: parseInt(route.params.zoom_level),
            }
        },
    },
    {
        path: '/m/:map_definition/:zoom_level',
        name: 'Map-Specific-WithZoom',
        component: DataMap,
        props: (route) => {
            return {
                map_definition: route.params.map_definition,
                zoom_level: parseInt(route.params.zoom_level),
            }
        },
    },
    {
        path: '/m/:map_definition',
        name: 'Map-Specific',
        component: DataMap,
        props: (route) => ({
            map_definition: route.params.map_definition,
            zoom_level: 0,
        }),
    },
    {
        path: '/similarities',
        component: Similarities,
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
    }
]

const router = new VueRouter({
  routes
})

export default router

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"datamap-outer"},[(_vm.selection_key != null)?_c('div',{staticClass:"datamap-domain-selection-header",style:({'background-color': _vm.selected_domain.color })},[_c('i',{staticClass:"fas fa-chevron-right"}),_vm._v(" "+_vm._s(_vm.selected_domain.name)+" ")]):_vm._e(),_c('div',{staticClass:"datamap-container"},_vm._l((_vm.grouped_datasets),function(dataset_group,index){return _c('div',{key:index,staticClass:"datamap-domain",class:[("domain-index-" + index),
                     _vm.getClassGroupWidth(dataset_group.datasets.length)
             ],style:({
                  '--base-color': _vm.meta.fields[_vm.options.category_field].values[dataset_group.domain].color,
                  '--base-color-dark': _vm.lighten(_vm.meta.fields[_vm.options.category_field].values[dataset_group.domain].color, -15),
                  '--base-color-light': _vm.lighten(_vm.meta.fields[_vm.options.category_field].values[dataset_group.domain].color, 10),
                  '--text-color': _vm.textcolor(_vm.meta.fields[_vm.options.category_field].values[dataset_group.domain].color)
             })},[_c('h3',[_c('i',{staticClass:"fas",class:("fa-" + (_vm.meta.fields[_vm.options.category_field].values[dataset_group.domain].icon))}),_vm._v(" "+_vm._s(_vm.meta.fields[_vm.options.category_field].values[dataset_group.domain].name)+" ")]),_c('div',{staticClass:"inner"},[_vm._l((dataset_group.datasets),function(dataset,index){return [(index < 24)?_c('div',{key:dataset.title,staticClass:"dataset-block",class:{'hidden': _vm.filtered !== null && _vm.filtered.indexOf(dataset.title) == -1},on:{"click":function($event){return _vm.$emit('select', {
                                  zoom: _vm.meta.dataset_level,
                                  key: dataset.id }
                                  )}}},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(dataset[_vm.options.title_field]))]),_c('br'),_c('span',{staticClass:"subtitle"},[_vm._v(_vm._s(dataset[_vm.options.subtitle_field]))])]):_vm._e()]})],2)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }
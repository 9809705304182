<template>
    <div style="position: relative;
                min-height: 100vh;
                height: 100%;
                width: 100%;
                overflow: hidden;
        ">

        <transition :name="transitionName">
            <component
              :is="innerComponent"
              @select="$emit('select', $event)"
              :datasets="datasets"
              :search_key="search_key"
              :filters="filters"
              :meta="meta"
              :selection_key="selection_key"
              :options="zoom_definition"
            />
        </transition>
    </div>
</template>

<script>

import DataMapBlocklets from '@/components/DataMapBlocklets';
import DataMapDomain from '@/components/DataMapDomain';
import DataMapDataset from '@/components/DataMapDataset';

export default {

    components: {
        DataMapBlocklets,
        DataMapDomain,
        DataMapDataset,
    },

    data: () => ({
        transitionName: 'zoomin',
    }),

    props: {
        zoom_definition: {
            type: Object,
            required: true,
        },
        zoom_level: Number,
        selection_key: {
            type: String,
            required: false,
            default: null,
        },
        search_key: {
            type: String,
            required: false,
            default: ""
        },
        filters: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        datasets: {
            type: Array,
            required: true,
        },
        meta: {
            type: Object,
            required: true,
        },
    },

    watch: {
        zoom_level (to, from) {
            if (to < from)  {
                this.transitionName = 'zoomout';
            } else {
                this.transitionName = 'zoomin';
            }
        },
    },

    computed: {
        innerComponent() {
            switch (this.zoom_definition.type) {
                case 'blocklets':
                    return DataMapBlocklets;
                case 'domain-map':
                    return DataMapDomain;
                case 'dataset':
                    return DataMapDataset;
            }
            return null;
        },
    },
}
</script>



<style lang="scss">

.zoomin-enter-active, .zoomin-leave-active,
.zoomout-enter-active, .zoomout-leave-active,
{
    position: absolute;
    top: 0;
    transition: all .5s ease;
    transform-origin: 50% 50%;
}
.zoomin-enter, .zoomout-leave-to {
    transform: scale(.6);
    opacity: 0;
}
.zoomin-leave-to, .zoomout-enter {
    transform: scale(1.1);
    opacity: 0;
}

</style>

<template>
    <div class="dataset"
        >
        <h1>{{ dataset.title }}</h1>

        <div class="dataset-info-grid">
            <div class="dataset-description">
                <div v-if="descriptionHtml"
                     v-html="descriptionHtml"
                    >
                </div>
            </div>

            <div class="dataset-tags">
                <template v-for="t in meta.info_fields">
                    <div v-if="meta.fields[t].values[dataset[t]]"
                     :key="t"
                     class="tag-info"
                    >
                        <h4>{{ t }}</h4>
                        <span>{{ meta.fields[t].values[dataset[t]].name }}</span>
                    </div>
                </template>

            </div>
        </div>
    </div>
</template>


<script>

import marked from 'marked';

export default {

    props: {
        datasets: {
            type: Object,
            required: true,
        },
        selection_key: {
            type: String,
            required: true,
        },
        meta: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        dataset: null,
    }),

    computed: {
        descriptionHtml() {
            if (!this.dataset.description) {
                return null;
            }

            return marked(this.dataset.description);
        },
    },

    mounted() {
        console.log(this.selection_key);
        this.dataset = this.datasets.find((x) => x.id == this.selection_key);
        console.log(this.dataset);
    }
};

</script>


<style lang="scss">

.dataset {
    color: white;
    padding: 40px;

    h1 {
        max-width: 1120px;
    }

    a {
        color: lighten($d2g-blue, 70%) !important;
        transition: color .1s ease-out;
        &:hover {
            color: lighten($d2g-blue, 40%) !important;
        }
    }
}

.dataset-overlay-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($d2g-blue, .85);
    color: white;

    animation: fadeIn .2s ease-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes zoomIn {
    from {
        transform: scaleY(0);
        opacity: 0;
    }
    to {
        transform: scaleY(1);
        opacity: 1;
    }
}


.dataset-info-grid {
    display: grid;
    max-width: 1120px;
    grid-template-columns: auto 300px;
    grid-column-gap: 30px;

    a {
        overflow-wrap: break-word;
    }

    @media only screen and (max-width: 700px) {
        grid-template-columns: 100%;
        grid-column-gap: 0;

    }

    .dataset-description {
        h2 {
            font-size: 24px;
            margin: 0;
            margin-top: 20px;
        }

        p {
            margin: 10px 0 15px 0;
        }
    }

    .tag-info {
        h4 {
            text-transform: capitalize;
            margin-bottom: 0;
            font-size: 16px;
            opacity: .5;
        }

        span {
            font-size: 18px;
            font-weight: bold;
        }
    }
}
</style>



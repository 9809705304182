<template>
  <div id="app">
      <router-view/>
  </div>
</template>


<script>


export default { }
</script>

<style lang="scss">
html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: $d2g-blue;
}

#app {
  font-family: Raleway;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
    font-family: Nexa Bold;
}
</style>



























import { Component, Watch, Vue, Prop } from 'vue-property-decorator';

import DataMap from '@/components/DataMap.vue';

import Header from '@/components/Header.vue';

import data_datasets from '@/../data/datogether.json';
import meta_datogether from '@/../data/meta_datogether.json';
import data_duo from '@/../data/duo.json';
import meta_duo from '@/../data/meta_duo.json';

import data_gemeente_utrecht from '@/../data/datasets_gemeente_utrecht.json';
import meta_gemeente_utrecht from '@/../data/meta_gemeente_utrecht.json';

@Component({
  components: {
      DataMap,
      Header,
  },
})
export default class Home extends Vue {

    @Prop(String)
    map_definition: string;

    datasets = [];
    meta_definition : any = null;

    search_key: string = null;
    filter: any = null;
    orig_coordinates: any = null;

    @Prop({ default: 0 }) readonly zoom_level: number;
    @Prop({ type: String, required: false, default: null })
    selection_key: any;

    mounted() {
        this.loadDatasets();
    }

    select(selection: any): void {
        this.$router.push(
            `/m/${this.map_definition}/${selection.zoom}/${encodeURIComponent(selection.key)}`
        );
    }

    setZoom(zoom_level: number): void {
        if (zoom_level == this.zoom_level) return;

        this.$router.push(
            `/m/${this.map_definition}/${zoom_level}`
        );
    }

    searchUpdate(search_key: string): void {
        this.search_key = search_key;
    }
    filterUpdate(filter: any): void {
        this.filter = filter;
    }

    @Watch('map_definition')
    loadDatasets() {
        switch (this.map_definition) {
            case 'duo':
                this.datasets = data_duo;
                this.meta_definition = meta_duo;
                break;
            case 'gemeente_utrecht':
                this.datasets = data_gemeente_utrecht;
                this.meta_definition = meta_gemeente_utrecht;
                break;
            default:
                this.datasets = data_datasets;
                this.meta_definition = meta_datogether;
        }
    }
}

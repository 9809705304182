import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "vue-multiselect/dist/vue-multiselect.min.css";

import "../vendor/fontawesome5/css/fontawesome.min.css";
import "../vendor/fontawesome5/css/regular.min.css";
import "../vendor/fontawesome5/css/solid.min.css";
import "../vendor/fontawesome5/css/brands.min.css";

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

<template>
    <div class="datamap-blocklets-container"
        >
        <div class="datamap-domain-zoom0"
             :class="[`domain-index-${group_index}`,
                     getClassGroupWidth(dataset_group.datasets.length),
                     zoom_element != null && group_index == zoom_element ? 'start-zoomin' : ''
             ]"
             :style="{
              '--base-color': hex_to_rgb(meta.fields[options.category_field].values[dataset_group.domain].color),
              '--base-color-dark': lighten(meta.fields[options.category_field].values[dataset_group.domain].color, -15),
              '--base-color-light': lighten(meta.fields[options.category_field].values[dataset_group.domain].color, 10),
              '--text-color': textcolor(meta.fields[options.category_field].values[dataset_group.domain].color)
             }"
             v-for="(dataset_group, group_index) in grouped_datasets"
             :key="group_index"
            >

            <h3 @click="select(dataset_group, group_index)" >

                <i class="fas" :class="`fa-${meta.fields[options.category_field].values[dataset_group.domain].icon}`"></i>

                {{ meta.fields[options.category_field].values[dataset_group.domain].name }}
            </h3>
            <div class="inner">
                <template v-for="(dataset, index) in dataset_group.datasets">
                    <div
                         :key="index"
                         @click="$emit('select', { zoom: 3, key: dataset.id })"
                         class="dataset-blocklet"
                         :class="{'hidden': filtered !== null && filtered.indexOf(dataset.title) == -1,
                         }"
                        >
                        <template v-if="options.icon_field">
                            <i class="fas"
                               :class="meta.fields[options.icon_field].values[dataset[options.icon_field]].icon"></i>
                        </template>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>

import Fuse from 'fuse.js';


export default {

    data: () => ({
        grouped_datasets: [],
        idx: null,

        filtered: null,
        zoom_element: null,
    }),

    props: {
        search_key: {
            type: String,
            required: false,
            default: ""
        },
        filters: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        datasets: {
            type: Array,
            required: true,
        },
        meta: {
            type: Object,
            required: true,
        },
        options: {
            type: Object,
            required: true,
        },
        map_definition: {
            type: String,
            required: false,
            default: null,
        },
    },

    watch: {
        search_key(value) {
            if (value == "") {
                this.filtered = null;
                return;
            }

            let results = this.idx.search(value);
            this.filtered = results.map((ref) => ref.item.title);
        },

        datasets() {
            this.loadData()
        },
        filters: {
            deep: true,
            handler: function() {
                this.loadData();
            }
        },
    },

    mounted() {
        this.loadData();
    },

    methods: {
        hex_to_rgb(hex_value) {
            if (hex_value == null) return null;

            let hex = hex_value.slice(1);
            let num = parseInt(hex, 16);

            let r = (num >> 16);
            let g = ((num >> 8) & 0x00FF);
            let b = (num & 0x0000FF);

            return `${r}, ${g}, ${b}`;
        },
        textcolor(hex_value) {
            if (hex_value == null) return null;

            let hex = hex_value.slice(1);
            let num = parseInt(hex, 16);

            let r = (num >> 16) / 255;
            let g = ((num >> 8) & 0x00FF) / 255;
            let b = (num & 0x0000FF) / 255;

            let cmin = Math.min(r, g, b),
                cmax = Math.max(r, g, b),
                l = 0;

            l = (cmax + cmin) / 2;
            if (l > .5) return '#0d3c55';
            return '#ffffff';
        },

        lighten(hex_value, percentage) {
            if (hex_value == null) return null;

            let hex = hex_value.slice(1);
            let num = parseInt(hex, 16);

            let r = (num >> 16) / 255;
            let g = ((num >> 8) & 0x00FF) / 255;
            let b = (num & 0x0000FF) / 255;

            let cmin = Math.min(r, g, b),
                cmax = Math.max(r, g, b),
                delta = cmax - cmin,
                h = 0,
                s = 0,
                l = 0;

            if (delta == 0) { h = 0; }
            if (cmax == r) { h = ((g - b) / delta) % 6; }
            if (cmax == g) { h = ((b - r) / delta) + 2; }
            if (cmax == b) { h = ((r - g) / delta) + 4; }

            h = Math.round(h * 60);
            if (h < 0) { h += 360; }

            l = (cmax + cmin) / 2;
            l += (percentage / 100);
            s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

            s = (s * 100).toFixed(1);
            l = (l * 100).toFixed(1);

            if (l < 0) { l = 0; }
            if (l > 100) { l = 100; }

            return `hsl(${h}, ${s}%, ${l}%)`;
        },

        select(dataset_group, group_index) {
            this.zoom_element = group_index;
            this.$emit('select', {
                'zoom': 2,
                'key': dataset_group.domain
            });
        },

        getClassGroupWidth(dataset_count) {
            if (dataset_count > 20) return 'col3';
            if (dataset_count > 7) return 'col2';
            return '';
        },

        loadData() {
            let gkey = this.options.category_field;
            let datasets = this.datasets
                .filter((d) => {
                    if (this.filters == null) return true;

                    return Object.keys(this.filters)
                        .map((key) => {
                            return this.filters[key] == null || d[key] == this.filters[key].key;
                        })
                        .reduce((rv, x) => rv && x, true);
                });

            let groups = datasets.reduce(function(rv, x) {
                (rv[x[gkey]] = rv[x[gkey]] || []).push(x);
                return rv;
            }, {});
            this.grouped_datasets = Object.entries(groups)
                .map(([key, value]) => {
                    return { domain: key, datasets: value };
                });
            this.grouped_datasets.sort((a, b) => {
                return b.datasets.length - a.datasets.length;
            });

            let d = this.datasets;

            this.idx = new Fuse(
                this.datasets,
                {
                    minMatchCharLength: 3,
                    keys: ['description', 'title'],
                }
            );
        },
    },
}
</script>



<style lang="scss">

.datamap-blocklets-container {
     color: white;
     padding: 40px;
    @media only screen and (max-width: 900px) {
        padding: 10px;
    }
}


@mixin domain-color($base-color, $text-color) {
}

@keyframes start-zoomin {
    from {
        transform: none;
        opacity: 1;
    }
    to {
        transform: scale(5);
        opacity: 0;
    }
}

.datamap-domain-zoom0 {
    position: relative;
    box-sizing: border-box;
    width: calc(20% - 10px);
    @media only screen and (max-width: 900px) {
        width: calc(100% - 10px);
    }

    // default colors
    --base-color: #aaaaaa;
    --base-color-dark: #888888;
    --base-color-light: #cccccc;

    color: var(--text-color);
    background-color: rgba(var(--base-color), .15);

    h3 { color: var(--base-color-dark); }

    .dataset-blocklet {
        background-color: var(--base-color-light);
        border: 1px solid rgba(var(--base-color), 1);

        &:hover {
            background-color: var(--base-color-light);
            border: 1px solid var(--base-color-dark);
            color: var(--text-color);
        }
    }

    margin: 5px;
    float: left;

    &.start-zoomin {
        animation: start-zoomin .7s ease-out;
    }

    border-radius: 8px;
    padding: 20px;

    h3 {
        font-size: 24px;
        top: 40%;
        margin-bottom: 2px;
        margin-top: 5px;
        transform-origin: bottom left;
        transition: all .15s ease-out;
        opacity: .8;

        &:hover {
            cursor: pointer;
            opacity: 1;
            text-decoration: underline;
        }
    }

    .dataset-blocklet {
        border-radius: 2px;
        width: 20px;
        height: 20px;
        margin: 2px;
        position: relative;
        display: inline-block;
        text-align: center;

        &.hidden {
            display: none;
        }

        &:hover {
            transform: scale(1.4);
        }

        i {
            text-align: center;
            font-size: 15px;
            line-height: 20px;
        }

        transition: all .2s ease-out;
        cursor: pointer;
    }
}

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"datamap-blocklets-container"},_vm._l((_vm.grouped_datasets),function(dataset_group,group_index){return _c('div',{key:group_index,staticClass:"datamap-domain-zoom0",class:[("domain-index-" + group_index),
                 _vm.getClassGroupWidth(dataset_group.datasets.length),
                 _vm.zoom_element != null && group_index == _vm.zoom_element ? 'start-zoomin' : ''
         ],style:({
          '--base-color': _vm.hex_to_rgb(_vm.meta.fields[_vm.options.category_field].values[dataset_group.domain].color),
          '--base-color-dark': _vm.lighten(_vm.meta.fields[_vm.options.category_field].values[dataset_group.domain].color, -15),
          '--base-color-light': _vm.lighten(_vm.meta.fields[_vm.options.category_field].values[dataset_group.domain].color, 10),
          '--text-color': _vm.textcolor(_vm.meta.fields[_vm.options.category_field].values[dataset_group.domain].color)
         })},[_c('h3',{on:{"click":function($event){return _vm.select(dataset_group, group_index)}}},[_c('i',{staticClass:"fas",class:("fa-" + (_vm.meta.fields[_vm.options.category_field].values[dataset_group.domain].icon))}),_vm._v(" "+_vm._s(_vm.meta.fields[_vm.options.category_field].values[dataset_group.domain].name)+" ")]),_c('div',{staticClass:"inner"},[_vm._l((dataset_group.datasets),function(dataset,index){return [_c('div',{key:index,staticClass:"dataset-blocklet",class:{'hidden': _vm.filtered !== null && _vm.filtered.indexOf(dataset.title) == -1,
                     },on:{"click":function($event){return _vm.$emit('select', { zoom: 3, key: dataset.id })}}},[(_vm.options.icon_field)?[_c('i',{staticClass:"fas",class:_vm.meta.fields[_vm.options.icon_field].values[dataset[_vm.options.icon_field]].icon})]:_vm._e()],2)]})],2)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }